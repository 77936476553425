var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('member-container',[_c('b-card',{staticClass:"pl-2 pr-2 pt-1"},[_c('div',{staticClass:"d-flex flex-wrap justify-content-between ml-50 mb-2"},[_c('h4',[_vm._v(_vm._s(_vm.$t("chat.title")))])]),_c('b-card',{staticClass:"tabla"},[(_vm.allChatRooms.length > 0)?_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"menu x-scroll-container",staticStyle:{"border":"none"}},[_c('b-nav',{staticClass:"w-60",attrs:{"vertical":""}},[_c('app-scroll',{ref:"refChatLogPS",staticClass:"user-chats scroll-area",attrs:{"options":{ scrollPanel: { scrollingX: false } }}},_vm._l((_vm.allChatRooms),function(chat){return _c('b-nav-item',{key:chat.key,staticClass:"border-bottom mr-1 py-25",class:{ active: _vm.isActiveRecibidos },on:{"click":function($event){_vm.openChat(
                      chat.isGlobal
                        ? _vm.community
                        : (_vm.communitySlug + "-" + ((
                          Number(chat.uniqueNumber) +
                          Number(_vm.member.uniqueNumber)
                        ).toString())),
                      chat
                    )}}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('user-avatar',{staticClass:"avatar-border-2 box-shadow-1 mr-50",attrs:{"user":chat,"size":"36","variant":"transparent"}}),_c('div',{staticClass:"w-100"},[(chat.lastMessage)?_c('div',{staticClass:"d-flex justify-content-between"},[_c('p',{staticClass:"title text-dark m-0 html-text-ellipsis-1"},[_vm._v(" "+_vm._s((chat.name === "undefined undefined" ? _vm.translate(_vm.collective.name) : _vm.translate(chat.name)) + " ")+" ")]),_c('p',{staticClass:"small text-muted mb-0"},[_vm._v(" "+_vm._s(_vm.timestampToTime(chat.lastMessage.sentAt))+" ")])]):_vm._e(),_c('p',{staticClass:"last-message text-muted html-text-ellipsis-1 mb-0",domProps:{"innerHTML":_vm._s(
                          ((chat.lastMessage.by === _vm.member.key ? 'me:' : '') + " " + (chat.lastMessage.message
                            .replace('<p>', '')
                            .replace('</p>', '')))
                        )}})])],1)])}),1)],1)],1)]),_c('b-col',{key:_vm.room,attrs:{"cols":"8"}},[_c('div',{staticClass:"chat-card d-flex flex-column justify-content-between"},[(_vm.user)?_c('b-link',{staticClass:"d-flex align-items-center w-100 border-bottom pb-75 pt-1",attrs:{"to":_vm.getMemberLocation(_vm.user)}},[_c('user-avatar',{staticClass:"avatar-border-2 box-shadow-1 mr-1",attrs:{"user":_vm.user,"size":"42","variant":"transparent"}}),_c('div',{staticClass:"user"},[_c('p',{staticClass:"title text-primary h4 m-0 html-text-ellipsis-1"},[_vm._v(" "+_vm._s(_vm.user.name === "undefined undefined" ? _vm.translate(_vm.collective.name) : _vm.translate(_vm.user.name))+" ")]),_c('p',{staticClass:"text-muted small m-0 html-text-ellipsis-1 info"},[_vm._v(" "+_vm._s(_vm.$t("chat.all-chats.contact-info"))+" ")])])],1):_vm._e(),_c('section',{staticClass:"chat-app-window d-flex flex-column justify-content-between h-100 pt-75"},[[_c('ChatArea2')]],2)],1)])],1):_c('b-row',{staticClass:"d-flex placeholder align-item-center justify-content-center flex-column my-2",staticStyle:{"min-height":"45vh"}},[_c('b-img',{staticClass:"placeholder-img",attrs:{"src":_vm.placeholderImage,"center":""}}),_c('div',{staticClass:"mt-2 text-center"},[_c('p',{staticClass:"text-primary font-weight-500"},[_vm._v(" "+_vm._s(_vm.$t("chat.error-messages.empty-message"))+" ")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }