<template>
  <member-container>
    <b-card class="pl-2 pr-2 pt-1">
      <div class="d-flex flex-wrap justify-content-between ml-50 mb-2">
        <h4>{{ $t("chat.title") }}</h4>
      </div>
      <b-card class="tabla">
        <b-row v-if="allChatRooms.length > 0">
          <!-- Conversations column -->
          <b-col cols="4">
            <div class="menu x-scroll-container" style="border: none">
              <b-nav vertical class="w-60">
                <app-scroll
                  ref="refChatLogPS"
                  :options="{ scrollPanel: { scrollingX: false } }"
                  class="user-chats scroll-area"
                >
                  <b-nav-item
                    v-for="chat in allChatRooms"
                    :key="chat.key"
                    :class="{ active: isActiveRecibidos }"
                    class="border-bottom mr-1 py-25"
                    @click="
                      openChat(
                        chat.isGlobal
                          ? community
                          : `${communitySlug}-${(
                            Number(chat.uniqueNumber) +
                            Number(member.uniqueNumber)
                          ).toString()}`,
                        chat
                      )
                    "
                  >
                    <div class="d-flex align-items-center">
                      <user-avatar
                        :user="chat"
                        size="36"
                        variant="transparent"
                        class="avatar-border-2 box-shadow-1 mr-50"
                      />

                      <div class="w-100">
                        <div
                          v-if="chat.lastMessage"
                          class="d-flex justify-content-between"
                        >
                          <p class="title text-dark m-0 html-text-ellipsis-1">
                            {{
                              (chat.name === "undefined undefined"
                                ? translate(collective.name)
                                : translate(chat.name)) + " "
                            }}
                          </p>
                          <p class="small text-muted mb-0">
                            {{ timestampToTime(chat.lastMessage.sentAt) }}
                          </p>
                        </div>
                        <p
                          class="last-message text-muted html-text-ellipsis-1 mb-0"
                          v-html="
                            `${
                              chat.lastMessage.by === member.key ? 'me:' : ''
                            } ${chat.lastMessage.message
                              .replace('<p>', '')
                              .replace('</p>', '')}`
                          "
                        />
                      </div>
                    </div>
                  </b-nav-item>
                </app-scroll>
              </b-nav>
            </div>
          </b-col>

          <!-- Chat content column -->
          <b-col :key="room" cols="8">
            <div class="chat-card d-flex flex-column justify-content-between">
              <!-- Room information -->
              <b-link
                v-if="user"
                class="d-flex align-items-center w-100 border-bottom pb-75 pt-1"
                :to="getMemberLocation(user)"
              >
                <user-avatar
                  :user="user"
                  size="42"
                  variant="transparent"
                  class="avatar-border-2 box-shadow-1 mr-1"
                />
                <div class="user">
                  <p class="title text-primary h4 m-0 html-text-ellipsis-1">
                    {{
                      user.name === "undefined undefined"
                        ? translate(collective.name)
                        : translate(user.name)
                    }}
                  </p>
                  <p class="text-muted small m-0 html-text-ellipsis-1 info">
                    {{ $t("chat.all-chats.contact-info") }}
                  </p>
                </div>
              </b-link>
              
              <!-- Chat content -->
              <section
                class="chat-app-window d-flex flex-column justify-content-between h-100 pt-75"
              >
                <template>
                  <ChatArea2 />
                  <!-- <chat-area class="chat-area" /> -->
                  <!-- <chat-component :room="room" :is-from-global-chat="true" /> -->
                </template>
              </section>
            </div>
          </b-col>
        </b-row>
        <!-- There are no messages -->
        <b-row
          v-else
          class="d-flex placeholder align-item-center justify-content-center flex-column my-2"
          style="min-height: 45vh"
        >
          <b-img :src="placeholderImage" class="placeholder-img" center />
          <div class="mt-2 text-center">
            <p class="text-primary font-weight-500">
              {{ $t("chat.error-messages.empty-message") }}
            </p>
          </div>
        </b-row>
      </b-card>
    </b-card>
  </member-container>
</template>

<script>
import MemberContainer from "@/views/myself/components/MemberSettingContainer.vue";
import UserAvatar from "@core/components/user/UserAvatar.vue";
import ChatPlaceholder from "@/assets/images/placeholders/light/chat.svg";
// import ChatLog from '@/views/apps/chatv2/components/ChatLog.vue';
import AppScroll from "@core/components/app-scroll/AppScroll.vue";
// import ChatComponent from "@/views/apps/chatv2/components/chatComponent.vue";
import ChatArea2 from "@/views/apps/chatv2/components/ChatArea2.vue";
import { CHAT_GETTERS } from "@/views/apps/chat/constants/chat-store-constants";
import moment from "moment";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";

export default {
  name: "ChatMyself",
  components: {
    MemberContainer,
    UserAvatar,
    // ChatLog,
    AppScroll,
    // ChatComponent,
    ChatArea2,
  },
  data() {
    return {
      isActiveRecibidos: false,
      isLoading: false,
      isDisabled: false,
      emptyMessage: "",
      chatInputMessage: "",
      newValue: [],
      room: null,
      user: this.member,
    };
  },
  computed: {
    communitySlug() {
      return this.$store.getters.currentCollective.slug;
    },
    community() {
      return this.$store.getters.currentCollective;
    },
    placeholderImage() {
      return ChatPlaceholder;
    },
    member() {
      return this.$store.getters.loggedMember;
    },
    roomGetter() {
      return this.$store.getters[CHAT_GETTERS.room];
    },
    allChatRooms() {
      return this.$store.getters.globalChat?.unpaginated || [];
    },
    chatData() {
      return this.$store.getters.chats[this.room]?.unpaginated || [];
    },
    /* isChatEmpty() {
      return this.chatData.length === 0;
    }, */
  },

  watch: {
    roomGetter(newValue) {
      console.log("ROOM GETTER NEW VALUE ---> ", newValue);
      this.room = newValue;
    },
  },

  sockets: {
    async newMessage(data) {
      console.log('New message received through the socket:', data);
      console.log('Checking if the chat is open... if not, send message in push notification');

      this.newValue = [...this.newValue, data];
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
  },
  async created() {
    this.newValue = null;
    if (this.room) {
      this.room = this.$store.getters[CHAT_GETTERS.room];
    }
    // 1) Join space chat room:
    this.$socket.emit(
      "join",
      this.$store.getters.currentCollective.slug,
      this.$store.getters.loggedMember.key,
    );
    // 2) Get all messages from this room:
    await this.fetchGlobalChatData();
    // 3) Open chat into the container (room, userData)
    // this.openChat(`${this.communitySlug}`, this.allChatRooms[0]);
    this.openChat(
      `${this.communitySlug}-${(
        Number(this.allChatRooms[0].uniqueNumber)
        + Number(this.member.uniqueNumber)
      ).toString()}`,
      this.allChatRooms[0],
    );
    // this.newValue = `${this.$store.getters.currentCollective.slug}-${(response.data[0].uniqueNumber + this.$store.getters.loggedMember.uniqueNumber).toString()}`;
  },
  methods: {
    translate(field) {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        field,
      );
    },
    async fetchGlobalChatData() {
      // const newValue = `${this.$store.getters.currentCollective.slug}-${(response.data[0].uniqueNumber + this.$store.getters.loggedMember.uniqueNumber).toString()}`;
      await this.$store.dispatch("getItemsSocket", {
        itemType: "globalChat",
        page: 1,
        perPage: 100,
        requestConfig: {
          room: this.member.key,
        },
      });
    },
    openChat(room, userData) {
      this.user = {
        name: userData.name,
        avatarURL: userData.avatarURL,
        username: userData.username,
      };
      this.room = room;
      this.$store.commit("chat/setIndividualChatUser", {
        user: this.user,
      });
    },
    getMemberLocation(user) {
      if (!user.username) {
        return null;
      }
      return {
        name: "member-details",
        params: {
          username: user.username,
          communityId: this.$route.params.communityId,
        },
      };
    },
    offsetHeight() {
      return this.$refs.refChatLogPS.getOffsetHeight();
    },
    scrollHeight() {
      return this.$refs.refChatLogPS.getScrollHeight();
    },
    scrollTop() {
      return this.$refs.refChatLogPS.getPosition().scrollTop;
    },
    scrollToBottom(scrollBottom = 0) {
      if (this.$refs.refChatLogPS) {
        this.$refs.refChatLogPS.scrollToBottom(scrollBottom, 0);
      }
    },
    timestampToTime(timestamp) {
      moment.locale(this.currentLocale);
      const startToday = moment({ hour: 0 }).unix();
      const week = moment().startOf("isoWeek").unix();
      if (timestamp > startToday) {
        return moment(timestamp).format("LT");
      }
      if (timestamp > week && timestamp < startToday) {
        return moment(timestamp).format("dddd");
      }
      return moment(timestamp).format("L");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include"; // Bootstrap includes

.menu {
  // border-top: 1px #d4d2d2 solid;
  border-right: 1px #d4d2d2 solid;
  min-height: 300px;
  height: 100%;
  max-height: 55vh;
  overflow-y: scroll;
}
.user {
  .info {
    display: block;
  }
}
/*.user:hover{
    .info{
        display: none;
    }
}*/
.last-message > p {
  margin-bottom: 0 !important;
}
.chat-label {
  border-top: none;
  border-radius: 5px;
  &--view {
    width: auto;
    position: relative;
  }
}
.placeholder-img {
  width: 120px;
  display: block;
}
.chat-card {
  height: 64vh !important;
}
.chat-area {
  height: calc(100% - 160px) !important;
}

@media (max-width: 592px) {
  .menutext {
    opacity: 0;
    font-size: 0.7em;
  }
}
</style>
